import * as React from 'react'

import type { CommonProps } from './field-internal'
import { FieldInternal } from './field-internal'
import { isLabelLessComponent } from './utils'

type Props = Omit<CommonProps, 'infoTip'>

export const FieldWithoutLabel = ({ children, ...props }: Props) => {
    if (!isLabelLessComponent(children)) {
        // eslint-disable-next-line no-console
        console.error(
            'FieldWithoutLabel should only be used with Switch or Checkbox because they come with their own label. Please use Field for other form controls so you can add a label.',
        )
    }

    return <FieldInternal {...props}>{children}</FieldInternal>
}

FieldWithoutLabel.displayName = 'FieldWithoutLabel'
