import type { Tag } from '@adverity/design-system'

import type {
    Datastream,
    DatastreamColumn,
    InsightsWorkspaceType,
    SchemaColumnBase,
    TargetBinding,
    Workspace,
} from '../types/entities'

const getDatastreamExtractsLinks = (datastreamId?: number) => {
    const datastreamPath = datastreamId ? `datastreams/${datastreamId}/` : ''

    return {
        list: `/api/web-app/${datastreamPath}datastream-extracts/`,
        actions: {
            scheduleDelete: `/api/web-app/${datastreamPath}datastream-extracts/actions/schedule-delete/`,
            undelete: `/api/web-app/${datastreamPath}datastream-extracts/actions/undelete/`,
            requeue: `/api/web-app/${datastreamPath}datastream-extracts/actions/requeue/`,
            requeue_sequential: `/api/web-app/${datastreamPath}datastream-extracts/actions/requeue-sequential/`,
            update_metadata: `/api/web-app/${datastreamPath}datastream-extracts/actions/update-metadata/`,
            apply_transformations: `/api/web-app/${datastreamPath}datastream-extracts/actions/apply-transformations/`,
        },
    }
}

const getDatastreamExtractionLogLinks = (datastreamId?: number) => {
    const datastreamPath = datastreamId ? `datastreams/${datastreamId}/` : ''

    return {
        list: `/api/web-app/${datastreamPath}extraction-logs/`,
        actions: {
            acknowledge: `/api/web-app/${datastreamPath}extraction-logs/actions/acknowledge/`,
            retry: `/api/web-app/${datastreamPath}extraction-logs/actions/retry/`,
            unacknowledge: `/api/web-app/${datastreamPath}extraction-logs/actions/unacknowledge/`,
        },
    }
}

export const API_URLS = {
    account: '/api/web-app/account/',
    authorization: {
        list: '/api/internal/ui/v1/authorizations/',
        systemManagedList: '/api/web-app/system_managed_authorizations/',
        filters_workspaces: '/api/internal/ui/v1/authorizations/filters/workspace/',
        filters_content_type: '/api/internal/ui/v1/authorizations/filters/content_type/',
        confirmDelete: '/api/web-app/authorizations/confirm-delete/',
        permissions: '/api/web-app/authorizations/permissions/',
        actions: {
            delete: '/api/web-app/authorizations/actions/delete/',
            fetch_metadata: '/api/web-app/authorizations/actions/fetch_metadata/',
            validate_credentials: '/api/web-app/authorizations/actions/validate_credentials/',
            export_csv: '/api/web-app/authorizations/actions/export_csv/',
        },
    },
    bindings: {
        details: (id: TargetBinding['id']) => `/api/web-app/bindings/${id}/`,
        list: '/api/web-app/bindings/',
        perDatastream: (datastreamId: Datastream['id']) => `/api/web-app/bindings/?datastream_id=${datastreamId}`,
    },
    block_usage: '/api/web-app/block-usage/',
    columnMapping: (options?: { datastreamId?: number; page?: number; pageSize?: number; removed?: boolean }) => {
        const params = new URLSearchParams()

        if (options?.datastreamId !== undefined) {
            params.append('datastream_id', options.datastreamId.toString())
        }

        if (options?.page !== undefined) {
            params.append('page', options.page.toString())
        }

        if (options?.pageSize !== undefined) {
            params.append('page_size', options.pageSize.toString())
        }

        if (options?.removed !== undefined) {
            params.append('removed', options.removed.toString())
        }

        const stringifiedParams = params.toString()

        return `/api/web-app/columns/${stringifiedParams ? `?${stringifiedParams}` : ''}`
    },
    constants: {
        core: '/api/web-app/constants/core/',
        target: '/api/web-app/constants/target/',
        enrichments: '/api/web-app/constants/enrichments/',
    },
    datastream: '/api/datastreams/',
    datastreams: {
        details: (id: Datastream['id']) => `/api/web-app/datastreams/${id}/`,
        datastreamSettingsForm: (datastreamId: number, form: string) =>
            `/api/web-app/datastream_settings/${datastreamId}/${form ? `${form}/` : ''}`,
        datastreamSettingsWarehouse: (datastreamId: number) =>
            `/api/web-app/datastream_settings/${datastreamId}/has_any_warehouse_imports/`,
        list: '/api/web-app/datastreams/',
        filters_connection: '/api/web-app/datastreams/filters/connection/',
        filters_content_type: '/api/web-app/datastreams/filters/content_type/',
        filters_creator: '/api/web-app/datastreams/filters/creator/',
        filters_target: '/api/web-app/datastreams/filters/target/',
        filters_workspaces: '/api/web-app/datastreams/filters/workspaces/',
        confirmDelete: '/api/web-app/datastreams/confirm-delete/',
        permissions: '/api/web-app/datastreams/permissions/',
        extracts: getDatastreamExtractsLinks,
        extractionLogs: (datastreamId: number) => getDatastreamExtractionLogLinks(datastreamId),
        actions: {
            delete: '/api/web-app/datastreams/actions/delete/',
            schedule_on: '/api/web-app/datastreams/actions/schedule_on/',
            schedule_off: '/api/web-app/datastreams/actions/schedule_off/',
            enable_destinations: '/api/web-app/datastreams/actions/enable_destinations/',
            disable_destinations: '/api/web-app/datastreams/actions/disable_destinations/',
            acknowledge_issues: '/api/web-app/datastreams/actions/acknowledge_issues/',
        },
        adminActions: (dastastreamId: number) => `/api/web-app/datastreams/${dastastreamId}/admin-actions/`,
    },
    connectorEngine: {
        jsonSchema: '/api/connector-engine/jsonschema/',
        jsonSchemaReference: '/api/connector-engine/reference/',
        connectors: '/api/connector-engine/connectors/',
        config: (connectorSlug: string) => `/api/connector-engine/config/${connectorSlug}/`,
        workflow: (connectorSlug: string, workflow: string) =>
            `/api/connector-engine/workflow/${connectorSlug}/${workflow}/`,
        workflowContext: (connectorSlug: string) => `/api/connector-engine/workflow-context/${connectorSlug}/`,
    },
    datasources: '/api/web-app/datasources/',
    enrichments: {
        list: '/api/web-app/enrichments/',
        detailed_list: '/api/web-app/enrichments/detailed_list/',
        filters_content_type: '/api/web-app/enrichments/filters/content_type/',
        filters_workspaces: '/api/web-app/enrichments/filters/workspaces/',
        confirmDelete: '/api/web-app/enrichments/confirm-delete/',
        permissions: '/api/web-app/enrichments/permissions/',
        sidebar_config: '/api/web-app/enrichments/get_listing_sidebar_context/',
        item: (id: string | number) => `/api/web-app/enrichments/${id}/`,
        types: '/api/web-app/enrichment-types/',
        workspaces: '/api/web-app/enrichment/workspaces/',
        datastreamTypes: '/api/web-app/enrichment/datastream-types/',
        datastreams: '/api/web-app/enrichment/datastreams/',
        defaultSourceColumns: '/api/web-app/enrichment/default-source-columns/',
        sourceColumns: '/api/web-app/enrichment/source-columns/',
        datastreamEnrichments: (datastreamId: number) =>
            `/api/web-app/enrichment/datastreams/${datastreamId}/enrichments/`,
        assignedDatastreams: (id: number) => `/api/web-app/enrichment/${id}/datastreams/`,
        assistantConfig: '/api/internal/ui/v1/enrichments/ai_assistant/config/',
        translation: {
            item: (id: string | number) => `/api/web-app/enrichment/translations/${id}/`,
            list: '/api/web-app/enrichment/translations/',
            initialData: '/api/web-app/enrichment/translations/initial_data/',
            fromLanguages: '/api/web-app/enrichment/translations/from_languages/',
            toLanguages: '/api/web-app/enrichment/translations/to_languages/',
            providers: '/api/web-app/enrichment/translations/providers/',
            preview: '/api/web-app/enrichment/translations/preview/',
        },
        conditional: {
            item: (id: string | number) => `/api/web-app/enrichment/conditionals/${id}/`,
            list: '/api/web-app/enrichment/conditionals/',
            initialData: '/api/web-app/enrichment/conditionals/initial_data/',
            preview: '/api/web-app/enrichment/conditionals/preview/',
        },
        'currency-conversion': {
            item: (id: string | number) => `/api/web-app/enrichment/currency/${id}/`,
            list: '/api/web-app/enrichment/currency/',
            initialData: '/api/web-app/enrichment/currency/initial_data/',
            fromCurrency: '/api/web-app/enrichment/currency/from_currency/',
            toCurrency: '/api/web-app/enrichment/currency/to_currency/',
            services: '/api/web-app/enrichment/currency/currency_services/',
            preview: '/api/web-app/enrichment/currency/preview/',
        },
        custom: {
            cloneItem: (id: string | number) => `/api/web-app/enrichment/custom/${id}/clone/`,
            item: (id: string | number) => `/api/web-app/enrichment/custom/${id}/`,
            list: '/api/web-app/enrichment/custom/',
            initialData: '/api/web-app/enrichment/custom/initial_data/',
            preview: '/api/web-app/enrichment/custom/preview/',
        },
        'optionally-conditional': {
            item: (id: string | number) => `/api/web-app/enrichment/optionally-conditionals/${id}/`,
            list: '/api/web-app/enrichment/optionally-conditionals/',
            initialData: '/api/web-app/enrichment/optionally-conditionals/initial_data/',
            preview: '/api/web-app/enrichment/optionally-conditionals/preview/',
        },
        geo: {
            item: (id: string | number) => `/api/web-app/enrichment/geo/${id}/`,
            list: '/api/web-app/enrichment/geo/',
            locationTypes: '/api/web-app/enrichment/geo/source-columns/',
            initialData: '/api/web-app/enrichment/geo/initial_data/',
            preview: '/api/web-app/enrichment/geo/preview/',
        },
        join: {
            item: (id: string | number) => `/api/web-app/enrichment/join/${id}/`,
            list: '/api/web-app/enrichment/join/',
            initialData: '/api/web-app/enrichment/join/initial_data/',
            preview: '/api/web-app/enrichment/join/preview/',
        },
        'smart-naming-convention': {
            item: (id: string | number) => `/api/web-app/enrichment/smart-naming-conventions/${id}/`,
            list: '/api/web-app/enrichment/smart-naming-conventions/',
            initialData: '/api/web-app/enrichment/smart-naming-conventions/initial_data/',
            samples: '/api/web-app/enrichment/smart-naming-conventions/samples/',
            constraints: '/api/web-app/enrichment/smart-naming-conventions/constraints/',
        },
        map: {
            columnUniqueValues: '/api/web-app/enrichments/column-unique-values/',
            item: (id: string | number) => `/api/web-app/enrichment/map/${id}/`,
            initialData: '/api/web-app/enrichment/map/initial_data/',
            list: '/api/web-app/enrichment/map/',
            preview: '/api/web-app/enrichment/map/preview/',
        },
        actions: {
            delete: '/api/web-app/enrichments/actions/delete/',
            export: '/api/web-app/enrichments/actions/export/',
        },
    },
    scheduling: {
        'smart-schedules': {
            list: '/api/web-app/smart-schedules/',
            item: (id: string | number) => `/api/web-app/smart-schedules/${id}/`,
            status: '/api/web-app/smart-schedules/status/',
            availableTimeRanges: '/api/web-app/smart-schedules/available-time-ranges/',
        },
    },
    extracts: {
        ...getDatastreamExtractsLinks(),
        filters_datastream_types: '/api/web-app/datastream-extracts/filters/datastream_type/',
        filters_workspaces: '/api/web-app/datastream-extracts/filters/workspaces/',
    },
    errors: '/api/errors/',
    jobActions: {
        deleteExtracts: (jobId: number) => `/api/web-app/jobs/${jobId}/delete_extracts/`,
        enrichThenImportExtracts: (jobId: number) => `/api/web-app/jobs/${jobId}/enrich_then_import_extracts/`,
        updateExtractsMetadata: (jobId: number) => `/api/web-app/jobs/${jobId}/update_extracts_metadata/`,
    },
    extractionLogs: {
        ...getDatastreamExtractionLogLinks(),
        filters_datastream_types: '/api/web-app/extraction-logs/filters/datastream_type/',
    },
    jobs: '/api/web-app/jobs',
    jobs_daily_stats: '/api/web-app/jobs/daily-stats/',
    logout: '/logout/',
    marketplace: {
        context: '/api/web-app/marketplace/',
    },
    pendingAuthorizations: {
        requestAccess: '/api/web-app/pending-authorizations/actions/request-access/',
    },
    resetPassword: ({ uidb64, token }: { uidb64: string; token: string }) =>
        `/api/web-app/accounts/reset/${uidb64}/${token}/`,
    schema_columns: {
        index: '/api/internal/ui/v1/schema/',
        recommendations: (columnId: DatastreamColumn['id']) =>
            `${API_URLS.columnMapping()}${columnId}/schema-recommendations/`,
        search: (searchQuery?: string) =>
            `${API_URLS.schema_columns.index}${searchQuery ? `?ordering=most_relevant,name&q=${searchQuery}` : ''}`,
        nameUsage: '/api/internal/ui/v1/schema/name-usage/',
    },
    schemaColumnsMasks: {
        perBinding: (bindingId: number) => `/api/internal/ui/v1/bindings/${bindingId}/schema_column_masks/`,
        perSchemaColumnInfo: (schemaColumnInfoId: number) =>
            `/api/internal/ui/v1/columns/${schemaColumnInfoId}/overwrites/`,
    },
    site_status: '/api/status/',
    target: {
        actions: {
            delete: '/api/web-app/targets/actions/delete/',
        },
        confirmDelete: '/api/web-app/targets/confirm-delete/',
        detailed_list: '/api/web-app/targets/detailed_list/',
        filters_content_type: '/api/web-app/targets/filters/content_type/',
        filters_workspaces: '/api/web-app/targets/filters/workspaces/',
        items: '/api/web-app/targets/',
        perStack: (stackId: Workspace['id']) => `/api/web-app/targets/?stack_id=${stackId}`,
        perStackWithFamily: (stackId: Workspace['id']) => `/api/web-app/targets/?stack_with_family=${stackId}`,
        sidebar_config: '/api/web-app/targets/get_listing_sidebar_context/',
    },
    transformation_schema: '/api/transformation/schema/',
    ui: {
        user: {
            index: '/api/internal/ui/v1/user/',
            onboarding: '/api/internal/ui/v1/user/onboarding/',
        },
        instance: '/api/internal/ui/v1/instance/',
        workspace: {
            index: (id: string) => `/api/internal/ui/v1/workspaces/${id}/`,
        },
        datastream: {
            show_looker_studio_after_transfer_info: (id: number) =>
                `/api/internal/ui/v1/datastream/${id}/looker_studio_onboarding_modal_info/`,
            extracts: {
                actions: {
                    requeueSequential: (datastreamId: number) =>
                        `/api/internal/ui/v1/datastream/${datastreamId}/extracts/actions/requeue-sequential/`,
                },
            },
            autoMap: (dastastreamId: number) => `/api/internal/ui/v1/datastream/${dastastreamId}/auto-map-columns/`,
        },
        guided_onboarding: {
            index: (guidedOnboardingId: number | string) =>
                `/api/internal/ui/v1/guided_onboarding/${guidedOnboardingId}/`,
            getByWorkspace: (workspaceId: string) => `/api/internal/ui/v1/workspaces/${workspaceId}/guided_onboarding/`,
            sourceStep: (sourceId: number) => `/api/internal/ui/v1/guided_onboarding/source_step/${sourceId}/`,
        },
    },
    workspaces: '/api/stacks/',
    subscriptions: '/api/internal/ui/v1/subscriptions/',
} as const

export const VIEW_URLS = {
    create_destination: '/target/basetarget/add/',
}

export const MEASURES_MAP = {
    SUM: 'Sum',
    AVG: 'Average',
    COUNT: 'Count',
    MIN: 'Min',
    MAX: 'Max',
    NONAGG: "Don't aggregate",
}

export const MEASURES = ['SUM', 'AVG', 'COUNT', 'MIN', 'MAX', 'NONAGG'] as const

export type MeasureType = (typeof MEASURES)[number]

export const DATA_TYPES = {
    1: 'String',
    2: 'Long',
    3: 'Float',
    4: 'Date',
    5: 'DateTime',
    6: 'Boolean',
    7: 'Percentage',
    8: 'JSON',
    9: 'Currency',
    10: 'Formula',
    11: 'Duration',
} as const

export const defaultMeasures: Record<DataSchemaType, SchemaColumnBase['measure']> = {
    STRING: null,
    LONG: 'SUM',
    FLOAT: 'SUM',
    DATE: null,
    DATETIME: null,
    BOOLEAN: null,
    PERCENTAGE: 'AVG',
    JSON: null,
    CURRENCY: 'SUM',
    FORMULA: null,
    DURATION: null,
}

export const DataSchemaTypes = [
    'STRING',
    'LONG',
    'FLOAT',
    'DATE',
    'DATETIME',
    'BOOLEAN',
    'PERCENTAGE',
    'JSON',
    'CURRENCY',
    'FORMULA',
    'DURATION',
] as const

export type DataSchemaType = (typeof DataSchemaTypes)[number]

export const OLD_DATA_TYPES_MAP: Record<keyof typeof DATA_TYPES, DataSchemaType> = {
    1: 'STRING',
    2: 'LONG',
    3: 'FLOAT',
    4: 'DATE',
    5: 'DATETIME',
    6: 'BOOLEAN',
    7: 'PERCENTAGE',
    8: 'JSON',
    9: 'CURRENCY',
    10: 'FORMULA',
    11: 'DURATION',
} as const

export const DATATYPE_CHOICES = Object.values(DATA_TYPES)

export const INSIGHTS_WORKSPACE_TYPES: Record<string, InsightsWorkspaceType> = {
    COMPANY: 1,
    BUSINESS_ENTITY: 2,
    CLIENT: 3,
} as const

/** @public used in test */
export const schemaTypes = {
    string: 1,
    long: 2,
    float: 3,
    date: 4,
    dateTime: 5,
    boolean: 6,
    percentage: 7,
    json: 8,
    currency: 9,
    formula: 10,
    duration: 11,
} as const

export const labelColorToVariant: Record<string, React.ComponentProps<typeof Tag>['variant']> = {
    blue: 'brand',
    green: 'brand',
    grey: undefined,
    orange: 'warning',
    pink: 'brand',
    raw: 'brand',
    red: 'danger',
} as const

export const detailsUrl = 'details/'

export const queryLargePageSize = 300

export const DATASTREAM_EXTRACT_STATES = {
    STATE_COLLECTED: 1,
    STATE_PROCESSED: 2,
    STATE_IN_TRANSIT: 3,
    STATE_IMPORT_ERROR: 4,
    STATE_QUEUED: 5,
    STATE_ENRICH_ERROR: 6,
    STATE_PENDING: 7,
    STATE_DELETED: 8,
    STATE_NO_DATA_COLLECTED: 9,
    STATE_DEFERRED: 10,
    STATE_PARKED: 11,
    STATE_SUCCESS: 12,
    STATE_FAILED: 13,
    STATE_RAW: 14,
    STATE_OVERWRITTEN: 15,
    STATE_PROCESSING_ERROR: 16,
    STATE_PII_REMOVED: 17,
} as const
